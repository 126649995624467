<template>
  <div>
    <div v-if='goodsObject.goodsList && goodsObject.goodsList.length' class="goods-list" :style="{paddingTop:props.boxPaddingH+'px',paddingBottom: props.boxPaddingH+'px',paddingLeft: props.type===4 ? 0 :props.boxPaddingV+'px',paddingRight:props.type===4 ? 0 :props.boxPaddingV+'px',background: props.boxBgColor,display: props.type===4?'block':'flex'}">
      <div class='group-img-box' v-if='props.goodsType ==="group"'>
        <img :src="goodsObject.groupImg?(baseUrl + goodsObject.groupImg) : require('../../../assets/group-img-default.png')" alt="">
      </div>
      <template v-if='props.type === 0 ||props.type === 1||props.type === 2'>
        <div class="goods-item" v-for="(good,index) in goodsObject.goodsList" :key="good.productId||''+index + goodsObject.seqNum" :style="{width: `calc( (100% - ${props.type*24/(props.type+1)}px) / ${(props.type+1)})`,marginBottom: props.itemMargin+'px',borderRadius: props.isRound?'8px':'',marginRight:props.type!==0&&(index+1)%(props.type+1)!==0?24/(props.type+1)+'px':''}">
          <div class='demo-tag' v-if='good.productId.indexOf("xfxproductid")!==-1'>模版</div>
          <img class="goods-img" :style="{height:`calc( (375px - ${props.boxPaddingV * 2}px - ${props.type * 24 / (props.type + 1)}px) / ${(props.type + 1)} * ${props.imgScale})` ,borderRadius: props.isRound?'8px':''}" :src="baseUrl + good.mainPicPath" />
          <div class="detail-info">
            <p class="good-name">{{good.name}}</p>
            <p class="good-sub" v-if="props.showSubTitle">{{good.subTitle}}</p>
            <div class="good-sales-stock">
              <div class='good-sales'  v-if='props.showPoint&&good.points'>折标价：¥{{good.points}}</div>
              <div class='good-sales'  v-if='props.showSales'>销量：{{good.virtualSale||0}}件</div>
              <div class='good-stock' v-if='props.showStock'>库存：{{good.stock||0}}件</div>
            </div>
            <div class="price-box">
              <div class="price-content">
                <div class="good-price" :style='{maxWidth: props.showOriginalPrice?"50%":"100%",marginRight:props.showOriginalPrice?"8px":"0"}'>¥{{good.price}}</div>
                <div class="good-originalPrice" v-if="props.showOriginalPrice">¥{{good.originalPrice}}</div>
              </div>
              <img src="../../../assets/plus.png" class="shop-car-btn" v-if='props.showBuyBtn' alt="" style='width:20px'>
            </div>
          </div>
        </div>
      </template>
      <template v-if='props.type === 3'>
        <div class='goods-item-v' v-for="(good,index) in goodsObject.goodsList" :key="good.productId||''+index + goodsObject.seqNum" :style="{marginBottom: props.itemMargin+'px',borderRadius: props.isRound?'8px':''}">
          <div class='demo-tag' v-if='good.productId.indexOf("xfxproductid")!==-1'>模版</div>
          <img class="goods-img-v" :src="baseUrl + good.mainPicPath" :style="{borderRadius: props.isRound?'8px':''}" />
          <div class="detail-info detail-info-v">
            <p class="good-name">{{good.name}}</p>
            <p class="good-sub" v-if="props.showSubTitle">{{good.subTitle}}</p>
            <div class="good-sales-stock">
              <div class='good-sales'  v-if='props.showPoint&&good.points'>折标价：¥{{good.points}}</div>
              <div class='good-sales'  v-if='props.showSales'>销量：{{good.virtualSale||0}}件</div>
              <div class='good-stock' v-if='props.showStock'>库存：{{good.stock||0}}件</div>
            </div>
            <div class="price-box">
              <div class="price-content">
                <div class="good-price" :style='{maxWidth: props.showOriginalPrice?"50%":"100%",marginRight:props.showOriginalPrice?"8px":"0"}'>¥{{good.price}}</div>
                <div class="good-originalPrice" v-if="props.showOriginalPrice">¥{{good.originalPrice}}</div>
              </div>
              <img src="../../../assets/plus.png" class="shop-car-btn" v-if='props.showBuyBtn' alt="" style='width:20px'>
            </div>
          </div>
        </div>
      </template>
      <div v-if='props.type === 4' :style='{width: `calc(${goodsObject.goodsList.length *3}rem + ${(goodsObject.goodsList.length-1)*props.itemMargin-props.itemMargin + props.boxPaddingV*2}px )`,padding: "0px " +props.boxPaddingV+"px"}' class='goods-flex-box'>
        <div class="goods-item" v-for="(good,index) in goodsObject.goodsList" :key="good.productId||''+index + goodsObject.seqNum" :style="{width: '3rem',marginRight: index === goodsObject.goodsList.length-1 ? 0 : props.itemMargin+'px',borderRadius: props.isRound?'8px':'',marginBottom:0}">
          <div class='demo-tag' v-if='good.productId.indexOf("xfxproductid")!==-1'>模版</div>
          <img class="goods-img" :style="{height:`calc( 3rem * ${props.imgScale})`,borderRadius: props.isRound?'8px':'' }" :src="baseUrl + good.mainPicPath" />
          <div class="detail-info">
            <p class="good-name">{{good.name}}</p>
            <p class="good-sub" v-if="props.showSubTitle">{{good.subTitle}}</p>
            <div class="good-sales-stock">
              <div class='good-sales'  v-if='props.showPoint&&good.points'>折标价：¥{{good.points}}</div>
              <div class='good-sales'  v-if='props.showSales'>销量：{{good.virtualSale||0}}件</div>
              <div class='good-stock' v-if='props.showStock'>库存：{{good.stock||0}}件</div>
            </div>
            <div class="price-box">
              <div class="price-content">
                <div class="good-price" :style='{maxWidth: props.showOriginalPrice?"50%":"100%",marginRight:props.showOriginalPrice?"8px":"0"}'>¥{{good.price}}</div>
                <div class="good-originalPrice" v-if="props.showOriginalPrice">¥{{good.originalPrice}}</div>
              </div>
              <img src="../../../assets/plus.png" class="shop-car-btn" v-if='props.showBuyBtn' alt="" style='width:20px'>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <no-data text='请添加商品'></no-data>
    </div>
  </div>
</template>
<script>
export default {
  name: 'add-img',
  props: {
    props: {
      type: Object,
    },
    goodsObject: {
      type: Object,
    },
  },

  setup (props, { root }) {
    return {
      baseUrl: process.env.VUE_APP_IMG,
    }
  },
}
</script>
<style lang="less" scoped>
.goods-list {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  .group-img-box {
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 12px;
    }
  }
  .goods-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    background: #fff;
    overflow: hidden;
    position: relative;
    .goods-img {
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }
}
.demo-tag {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
  background: linear-gradient(to right, red, orange);
  color: #fff;
  padding: 0 8px;
}
.goods-item-v {
  width: 100%;
  position: relative;
  display: flex;
  background: #fff;
  overflow: hidden;
  padding: 8px;
  .goods-img-v {
    height: 2.4rem;
    width: 2.4rem;
    margin-right: 12px;
    object-fit: cover;
  }
  .detail-info-v {
    padding: 0;
    width: calc(100% - 2.4rem - 12px);
    .good-name {
      font-size: 16px;
      color: #000;
    }
  }
}

.detail-info {
  width: 100%;
  padding: 0.16rem 0.08rem;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .good-name {
    font-size: 14px;
    color: #000;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .good-sub {
    font-size: 12px;
    color: #666;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .good-sales-stock {
    font-size: 12px;
    color: #666;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    .good-sales {
      width: 50%;
      flex-shrink: 0;
    }
    .good-stock {
      width: 50%;
      flex-shrink: 0;
    }
  }
  .price-box {
    margin-top: 0.16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .price-content {
      width: calc(100% - 28px);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .good-price {
        color: rgb(255, 68, 68);
        font-size: 18px;
      }
      .good-originalPrice {
        color: #999;
        text-decoration: line-through;
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .shop-car-btn {
      color: rgb(255, 68, 68);
      font-size: 24px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}
.goods-flex-box {
  overflow: auto;
  display: flex;
}
</style>
